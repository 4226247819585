<template>
	<div class="email-confirmation-form">
		<PageTitle big>
			{{ $t('email-confirmation.title') }}
		</PageTitle>
		<i18n path="email-confirmation.description" tag="p" class="description">
			<template #email>
				<span class="description__email">
					{{ getEmail }}
				</span>
			</template>
		</i18n>

		<div class="resend">
			<CLabel small faded>
				{{ $t('email-confirmation.resend-label') }}
			</CLabel>

			<CButton
				class="resend__button"
				primary
				full-width
				:loading="resendEmail.loading"
				:success="resendEmail.success"
				@click="handleResendEmail"
			>
				{{ $t('email-confirmation.resend') }}
			</CButton>

			<CLabel v-if="resendEmail.throttled && !resendEmail.loading" small error>
				{{ $t('confirm-account.resend.throttled') }}
			</CLabel>
			<FormError :error="resendEmail.error" />
		</div>

		<div class="change-resend">
			<CLabel small faded bold>
				{{ $t('email-confirmation.still-no-email') }}
				<CLabel small faded>
					{{ $t('email-confirmation.change-resend-label') }}
				</CLabel>
			</CLabel>

			<MaterialInput id="fullname" v-model="form.email" type="text" :error="formErrors.email" />

			<CButton
				class="change-resend__button"
				primary
				full-width
				:disabled="form.email === getEmail"
				:loading="changeAndResendEmail.loading"
				:success="changeAndResendEmail.success"
				@click="handleChangeAndResendEmail"
			>
				{{ $t('email-confirmation.change-resend') }}
			</CButton>
		</div>

		<div v-if="networkError" class="network-error">
			<FormError :error="$t('errors.network-error')" />
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CButton from '@/shared/cbutton/CButton';
import CLabel from '@/shared/clabel/CLabel';
import MaterialInput from '@/components/material-input/MaterialInput';
import apiClient from '@/api';
import PageTitle from '@/components/page-title/PageTitle';

const FormError = () => import('../../shared/forms/FormError.vue');

export default {
	name: 'EmailConfirmationForm',
	components: {
		PageTitle,
		MaterialInput,
		CLabel,
		CButton,
		FormError,
	},
	data() {
		return {
			networkError: false,
			form: {
				email: '',
			},
			formErrors: {
				email: null,
			},
			resendEmail: {
				loading: false,
				error: null,
				throttled: false,
				success: false,
			},
			changeAndResendEmail: {
				loading: false,
				error: null,
				success: false,
			},
		};
	},
	computed: {
		...mapGetters(['getEmail']),
	},
	watch: {
		'resendEmail.success': function () {
			if (this.resendEmail.success) {
				setTimeout(() => (this.resendEmail.success = false), 2000);
			}
		},
		'changeAndResendEmail.success': function () {
			if (this.changeAndResendEmail.success) {
				setTimeout(() => (this.changeAndResendEmail.success = false), 2000);
			}
		},
	},
	async mounted() {
		this.form.email = this.getEmail;

		await this.pollForConfirmation();
	},
	methods: {
		...mapActions(['updateUser']),
		sleep(milliseconds) {
			return new Promise((resolve) => setTimeout(resolve, milliseconds));
		},
		async pollForConfirmation() {
			const confirmed = await apiClient.pollEmail();
			if (!confirmed) {
				await this.sleep(1500);
				await this.pollForConfirmation();
			} else {
				this.$router.push({
					path: '/',
				});
			}
		},
		async handleResendEmail() {
			this.resendEmail.loading = true;
			this.resendEmail.success = false;
			this.resendEmail.error = null;
			this.resendEmail.throttled = false;

			try {
				const { email } = await apiClient.resendConfirmation();

				this.form.email = email;
				this.resendEmail.success = true;
			} catch (error) {
				if (error?.response?.status === 429) {
					this.resendEmail.throttled = true;
				} else {
					this.resendEmail.error = error;
				}
			} finally {
				this.resendEmail.loading = false;
			}
		},
		async handleChangeAndResendEmail() {
			try {
				this.changeAndResendEmail.loading = true;
				this.changeAndResendEmail.success = false;
				this.changeAndResendEmail.error = null;
				this.formErrors.email = null;

				await this.updateUser({ email: this.form.email });

				this.changeAndResendEmail.success = true;
			} catch (error) {
				if (error.response?.data?.errors) {
					error.response.data.errors.map((value) => {
						this.formErrors[value.param] = value.msg;
					});
				} else {
					this.formErrors.email = error;
				}
			} finally {
				this.changeAndResendEmail.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';

.resend {
	margin-top: 2rem;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	&__button {
		margin-top: 10px;
	}
}

.change-resend {
	margin-top: 2rem;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	&__button {
		margin-top: 10px;
	}
}

.network-error {
	padding: 15px 0 15px 40px;
}

.description {
	text-align: start;
	color: rgba(255, 255, 255, 0.6);
	font-size: $title;

	&__email {
		color: white;
	}
}
</style>
