<template>
	<div class="email-confirmation-wrapper">
		<AuthLayout>
			<div class="aside aside__mail-confirm" />
			<div class="main">
				<div class="form-wrapper">
					<EmailConfirmationForm />
				</div>
			</div>
		</AuthLayout>
	</div>
</template>

<script>
import EmailConfirmationForm from '@/components/email-confirmation/EmailConfirmationForm';
import AuthLayout from '@/shared/auth-layout/AuthLayout';

export default {
	name: 'EmailConfirmation',
	components: {
		AuthLayout,
		EmailConfirmationForm,
	},
	methods: {},
};
</script>

<style scoped lang="scss">
@import '../../assets/style/_variables';

.email-confirmation-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.aside__mail-confirm {
	background: linear-gradient(180deg, #18191d 0%, rgba(24, 25, 29, 0.47) 27.6%, rgba(24, 25, 29, 0.81) 100%),
		url('../../assets/images/registration-mock.jpg') no-repeat center;
}

.form-wrapper {
	width: 50%;
	padding: 2rem 0.5rem 0.5rem;
	min-width: 380px;

	@media (max-width: $md) {
		& {
			width: 100%;
			min-width: unset;
		}
	}
}

@media (max-width: $md) {
	.container {
		flex-direction: column-reverse;
	}
}
</style>
